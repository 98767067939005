import React from 'react'



class OurVarious extends React.Component {


  render() {

    return (
      <section className="icon pt-100 secureof cryptoken padremove">
        <div className="container">
          <div className='heading-h2 text-center'>
          <h3 className="heading-h2">Our Token Development Services</h3>
          <span className='bluecolor'>On Various Blockchains</span> 
          </div>
          <p className='text-center'>Our Crypto token development services cover most of the widely used tokens, whether fungible or non-fungible. We have expertise in launching tokens according to the blockchain type you want. Here are the different token services we offer.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 px-0 px-md-2' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ethereum.png" alt="Ethereum Token Development" />
                <a href='https://www.coinsclone.com/ethereum-token-development/'>Ethereum Token Development</a></h3>
                <p className="pharagraph">We thrive on building Ethereum tokens on any token standard including ERC-20, ERC-721, ERC-223, ERC-777, ERC-998, ERC 1155. Our Ethereum token development services are highly specialized in creating secure Ethereum tokens.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 px-0 px-md-2'>
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/tron.png" alt='Tron Token Development' />
                <a href='https://www.coinsclone.com/tron-token-development/'>Tron Token Development</a>
                </h3>
                <p className="pharagraph">Launch scalable and highly compatible Tron tokens using popular token standards like TRC-20 and TRC-721. Our tron tokens facilitate rapid transactions and have a distinct framework since it is built on the proof-of-stake consensus algorithm.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 px-0 px-md-2'>
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/solana.png" alt='Solana Token Development' />
                <a href='https://www.coinsclone.com/solana-token-development/'>Solana Token Development</a>
                </h3>
                <p className="pharagraph">Create Crypto tokens on the Solana blockchain, which allows more than 50,000 transactions per second. As a premier Solana token development services provider, we work on the proof-of-history consensus algorithm and your tokens can easily grab a lot of investors.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 px-0 px-md-2' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/polygon.png" alt='Polygon Token Development' />
                <a href='https://www.coinsclone.com/polygon-token-development/'>Polygon Token Development</a>
                </h3>
                <p className="pharagraph">Our polygon token development services leverage the maximum feasibility of creating and integrating tokens for all blockchain applications. We create Polygon Pegged tokens like Peggycoins and DinoSwap etc, with advanced features.</p>
              </div>
            </div>
          </div>
          <a href="/contact-us/" className="bluebtn mt-2 m-auto d-table">Hire Our Crypto Token Developers</a>
        </div>
      </section>
    )
  }
}

export default OurVarious