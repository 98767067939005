import { StaticImage } from "gatsby-plugin-image"
import React from "react"

class ValueAdded extends React.Component {

  render() {
    return (

      <section className="process-of cryptoken pt-100 mb-0">
          <div className="container"> 
            <div className="heading-h2 text-center">
              <span className="bluecolor">Add-On</span> In Our
              <h3 className="heading-h2">Token Development Services</h3>
            </div>
            
            <p className="text-center">
            We provide add-ons in our development services as a privilege to our clients.
            </p>
            <div className="row table-content">
            <div className="col-lg-4 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-token/post-launch.webp"
                alt="Post Launch Management"
                placeholder='none'
                className='mt-3 mb-3'
                width={304}
              />
            </div>
            <div className="col-lg-8 col-md-12">
            <p className="pharagraph">Once the tokens are developed and deployed, we provide post-launch token management services for our clients. We take care of the technical parameters involved in the token launch and community building.  </p>
            <p className="pharagraph mb-0">Our post-launch token management services can boost stability, increase liquidity, and ensure steady growth in token adoption and branding. We extend services on creating web 3.0 marketing strategies which include airdrops, contests, bug bounties, rewards, and referral programs to ensure the maximum reach in the competitive market.</p>
            </div>
          </div>
            <div className="d-lg-flex add-one mt-2 mt-lg-4">
              <div className="square" >
                <h3>Liquidity</h3>
                <p className="pharagraph">
                Our newly developed tokens have all the abilities to offer liquidity which is an essential component for quick trading. Our experts build your token with capabilities to reach a wider audience and resolve the issues of lower liquidities.
                </p>
              </div>
              <div className="square">
                <h3>Market Making</h3>
                <p className="pharagraph">
                Another important factor during token trading is market making. Our development services ensure the building of effective digital assets that can make a high market for themselves without extra effort.
                </p>
              </div>
              <div className="square">
                <h3>Community Marketing</h3>
                <p className="pharagraph">
                Apart from liquidity and market making, the tokens we develop can grab a lot of investors through community marketing. Since our experts focus on streamlined strategies, the tokens get distributed among a community at ease.
                </p>
              </div>
            </div>
          </div>
      </section>

    )
  }
}

export default ValueAdded
