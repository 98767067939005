import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class SecurityConsider extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h4 className="heading-h2"><span className="bluecolor">Compliance and Legal</span> Considerations in Token Development</h4>
              
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-token/security-considerations.webp"
                alt="Security Consider"
                placeholder='none'
                className='mt-3 mb-3'
                width={450}
              />
            </div>
            <div className="col-lg-6 col-md-12">
            <p className="pharagraph">Our expert developers prioritize security throughout the token creation process, using top technology stacks to ensure tokens thrive and sustain in the market. We address concerns about robustness and integrity by navigating the complex legal and regulatory landscape, ensuring compliance with relevant regulations.</p>
            <p className="pharagraph">Our Crypto Token development services also cover regulatory adherence, including registration with authorities, anti-money laundering (AML), know-your-customer (KYC) requirements, and avoiding securities law violations. This professional approach helps mitigate legal risks and supports the long-term success of your crypto tokens.</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default SecurityConsider