import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
                <h1 className="main-heading"><span className='bluecolor'>Crypto Token</span> Development Company</h1>
                <p className="pharagraph">Unlock the power of tokens with our exemplary crypto token development services. Our expert token developers create custom crypto tokens on the top 10 blockchains and support over 20 token standards, including ERC20, BEP20, TRC20, and more.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Create crypto token with us</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-token/crypto-token-development.png"
                alt="Crypto Token Development Services"
                className='d-none d-md-block'
                width={743}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection