import React from 'react'



class Exemplary extends React.Component { 
  
  
  render() {

    return (
      <section className="icon secureof pt-100 padremove">
        <div className="container">
          <div className='heading-h2 text-center'><span className='bluecolor'>Features Of Our</span> <h3 className='heading-h2'>Crypto Token Development Services</h3></div>
          <p className='text-center'>Our Crypto token creation comes with the best-in-class features covering different aspects. We also provide advanced features to ensure our Clients win the race among their competitors.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/token-minting.png" alt="Token minting" />
                Minting
                  </div>
                <p className="pharagraph">Our blockchain experts ensure that the tokens we create come with an inbuilt minting feature. Therefore, you can mint them on any platform you desire.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/token-burning.png" alt='Token burning' />
                Burning
                </div>
                <p className="pharagraph">We include the burning option while creating the tokens. Hence, you can remove any number of tokens using the burning option and reduce its supply.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/token-swapping.png" alt='Token swapping' />
                Swapping
                </div>
                <p className="pharagraph">Our creation comes with the swapping option which enables you to create and swap tokens on the DEX platform. You can also increase the user count on your platform by embracing the tokens we build which can be swapped.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/smart-contract.png" alt='Smart contract' />
                Smart Contract
                </div>
                <p className="pharagraph">Developing a digital asset ideally starts with preparing a detailed and perfect smart contract. Our Solidity language experts design smart contracts with a set of codes that run without any bugs and can be deployed.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/security.png" alt='Security' />
                Security
                </div>
                <p className="pharagraph">Our crypto token development solutions have inbuilt security features in the form of End-to-end encryption, Multilevel SSL security, Device-level native security, etc. Also, we provide additional security because we create virtual assets that hardly have any issues.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/marketing.png" alt='High market value' />
                High Market Value 
                </div>
                <p className="pharagraph">As superior Crypto developers, we create and design them in a way that is likely to have a high market. Hence, you do not have to spend more time on marketing which helps you to save considerable expenses.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 m-auto d-table">Create your Token Now</a>
          </div>
      </section>
    )
  }
}

export default Exemplary