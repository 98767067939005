import { StaticImage } from 'gatsby-plugin-image'
import React,{useEffect,useState} from 'react'


const Whatis =()=> {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

    return (
      <section className="whatis pt-3 pt-md-5">
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="heading-h2 text-center">
                <h2 className="heading-h2 mb-2">Token Development Company - <span className='bluecolor'>Coinsclone</span></h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a premier Crypto token development company that offers reliable and secure token development services on various blockchains. By launching a Crypto token with Coinsclone, you can get a 100% assured premium token with some extraordinary features. Our team of experts, designs and creates tokens in the best way possible to ensure your Crypto token development purpose is completely satisfied. 
              </p>
              <p className="pharagraph text-center mb-2 mb-md-5">Additionally, At Coinsclone, we provide top-notch token development services on extensive blockchains like Ethereum, Binance Smart Chain, TRON, Solana, Polygon, etc. From ideation to token launch and marketing, We have real-world experience in delivering comprehensive offerings on token development to our clients across industry hosts.
              </p>
              { isDesktop && (
            <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/crypto-token/leading-crypto-token-overview.png"
            alt="Leading Crypto Token Development Company Overview"
            width={1100}
          />
              )}
              { isMobile && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/crypto-token-development-mobile.webp" 
              alt="Leading Crypto Token Development Company Overview"
              width={255} />
              )}
            
                </div>
          </div>
        </div>
      </section>
    )
}

export default Whatis