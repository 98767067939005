import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class BenifitsOf extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <div className='heading-h2 text-center'><span className="bluecolor">Benefits of our</span>
            <h3 className="heading-h2">Crypto Token Development Services</h3>
            </div>
            <p>Our professional cryptocurrency developers can boost your business to the next level. Here are the outstanding benefits of our Cryptocurrency Token development services.</p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Financial Transactions
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Fundraising
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Branding
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Popularity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Greater Market Efficiency
                </li>
              </ul>

              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Financial Transactions
                        </h4>
                        <p className="pharagraph">Notably, crypto assets can be traded and are compatible with most exchanges. They act as digital assets for which the value keeps increasing from time to time.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/financial_transactions.png"
                      alt="Financial transactions"
                      width={229}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Fundraising</h4>
                        <p className="pharagraph">The ultimate purpose of creating tokens is for their usage in Crypto crowdfunding techniques like ICO, STO, IDO, IFO, etc. Hence, you can create it to raise large funds for your blockchain projects in a limited time.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/fund_raising.png"
                      alt="Fundraising"
                      width={340}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Branding</h4>
                        <p className="pharagraph">Creating virtual assets is one of the best ways to improve branding for businesses. Once the branding is completed, more profits can also be generated as a result on a longer basis.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/brand.png"
                      alt="Branding"
                      width={275}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Popularity
                        </h4>
                        <p className="pharagraph">Branding and popularity can be interlinked to an extent. A large number of users start cueing up your business platform if the token you have created is popular in the Crypto industry.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/popularities.png"
                      alt="Popularity"
                      width={252}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Greater Market Efficiency</h4>
                        <p className="pharagraph">Crypto assets that we create usually have a better market efficiency which is a significant parameter of developing virtual assets.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/greater_market_efficiency.png"
                      alt="Greater market efficiency"
                      width={230}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BenifitsOf